module.exports = {
    FROM_APP_QUERY_PARAM: 'from_app',
    FORCE_CACHE_REFRESH_QUERY_PARAM: 'force_cache_refresh',
    HANDLE_QUERY_PARAM: 'handle',
    LOCALHOST: 'localhost',
    CP_PREFIX: '/cp/',
    PAGE_SLUG_PARAM: 'page_slug',
    NONE: 'None',
    NOT_SET: 'NotSet',
    LEAN: 'Lean',
    STATS_PREFIX: 'services.content_pages_perseus',
    OBJECT_VALUES: 'Object.values',
    OBJECT_KEYS: 'Object.keys',
    ENUM: 'enum',
    OBJECT: 'object',
    STRING: 'string',
    NUMBER: 'number',
    BOOLEAN: 'boolean',
    OBJECT_FIELDS_VARIANTS: {
        RESPONSIVE: ['default', 'sm', 'md', 'lg', 'xl'],
        SELECTORS: ['default', 'hover', 'active', 'focus'],
        HOVER_SELECTOR: ['default', 'hover'],
        RESPONSIVE_AND_SELECTORS: ['default', 'sm', 'md', 'lg', 'xl', 'hover', 'active', 'focus'],
        FOCUSES: ['focus', 'focusVisible', 'focusWithin'],
    },
    COMPONENT_PREFIX: 'fiverr:',
    DEFAULT_OWNER: 'beavers',
    NO_INDEX: {
        name: 'robots',
        content: 'noindex',
    },
    BUILDER_QUERY_PARAM: 'builder',
    ATTEMPT_QUERY_PARAM: 'attempt',
    CUSTOM_COMPONENTS_CATEGORY: 'Custom Components',
    RUM_PREFIX: 'content-pages',
    VERSIONS: {
        V2: 'v2-',
    },
    ROLLOUTS: {},
    HTTP_FOUND: 302,
    TEMPORARY_REDIRECT: 307,
    HTTP_NOT_FOUND: 404,
    HTTP_INTERNAL_SERVER_ERROR: 500,
    LIVE_STRICT_PAGE_PATH: '/cp/live-strict-page',
    PAGE_MODEL_NAME: 'cp',
    STRICT_PAGE_MODEL_NAME: 'sp',
    BASE_CLOUDINARY_URL: 'https://fiverr-res.cloudinary.com/image/upload',
    CLOUDINARY_VISUALS_PATH: 'penta/visuals',
};
